<template>

    <div id="banner" class="jhon">
      <div id="banner-desktop" class="relative mt-3 hidden md:block">
        <Image :src="empresa.capaWeb" />
        <div id="banner-perfil">
          <div id="banner-perfil-logo" class="border-circle overflow-hidden">
            <img :src="empresa.foto" alt="">
          </div>
          <div id="banner-perfil-identificacao">
            <strong>{{empresa.nomeEmpresa}}</strong><br>
            <small v-if="$utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ) == ''">{{$utils.formatCnpjCpf(empresa.cnpj)}}</small>
            <small v-else-if="$utils.isCNPJ($utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ))">{{$utils.formatCnpjCpf(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ)}}</small>
            <small v-else>{{ empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ }}</small>
          </div>
          <div id="banner-perfil-contato">
            <Button label="WhatsApp" class="p-button-text p-button-success" style="padding-left: 5px; padding-right: 5px;" icon="pi pi-whatsapp" @click="whats"/>
            <Button label="Catálogo" class="p-button-text p-button-danger ml-2" style="padding-left: 5px; padding-right: 5px;" icon="pi pi-file-pdf" @click="catalogoPdf" v-if="empresa.catalogoPdf"/>
          </div>
        </div>
      </div>
      <div id="banner-mobile" class="relative mt-3 md:hidden">
        <!--<Image src="https://place-hold.it/800x800" />-->
        <Image :src="empresa.capaMobile" />
         <div id="banner-mobile-perfil">
          <div id="banner-mobile-perfil-logo" class="border-circle overflow-hidden">
            <img :src="empresa.foto" alt="">
          </div>
          <div id="banner-mobile-perfil-identificacao">
            <strong>{{empresa.nomeEmpresa}}</strong><br>
            <small v-if="$utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ) == ''">{{$utils.formatCnpjCpf(empresa.cnpj)}}</small>
            <small v-else-if="$utils.isCNPJ($utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ))">{{$utils.formatCnpjCpf(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ)}}</small>
            <small v-else>{{ empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ }}</small>
            <div>
              <Button label="WhatsApp" class="p-button-text p-button-success" style="padding: 4px 5px;" icon="pi pi-whatsapp" @click="whats"/>
              <Button label="Catálogo" class="p-button-text p-button-danger ml-2" style="padding: 4px 5px;" icon="pi pi-file-pdf" @click="catalogoPdf" v-if="empresa.catalogoPdf"/>
            </div>
          </div>
          
        </div>
      </div>
    </div>

</template>

<script>

export default {
  name: "Banner",
  data() {
    return {
     empresa:{}
   
    };
  },
  watch:{
     '$root.empresa': {
      handler: function(e) {
        if(e != null) {
          this.empresa = e;
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods:{
    whats() {
      window.open(this.$root.wp_link);
    },
    catalogoPdf() {
      window.open(this.empresa.catalogoPdf);
    },
  }
}

</script>

<style lang="scss" scoped>
.jhon{
  background-color: white;
  border-radius: 10px 10px 10px 10px;
}

#banner-desktop {
  padding-bottom: 90px;
}

#banner-perfil {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  padding: 0 40px;
  bottom: 0px;
}

#banner-perfil-logo {
  width: 130px;
  height: 130px;
  border-width: 4px;
  //border-color: var(--secondaryColor, $secondaryColor);
  border-color: white;
}

#banner-perfil-identificacao {
  width: calc(91% - 65px - 270px);
  padding: 0 15px;
  padding-bottom: 16px;
  font-size: 2em;
  line-height: 1;
}

#banner-perfil-identificacao strong {
  font-weight: 600;
}

#banner-perfil-identificacao small {
  font-size: 1.2rem;
}

#banner-perfil-contato {
  width: 270px;
  padding: 0 15px;
  padding-bottom: 16px;
  text-align: right;
}

#banner-mobile-perfil {
  display: flex;
  align-items: flex-end;
  width: 100%;
  //position: absolute;
  padding: 0 10px;
  bottom: 0px;
}

#banner-mobile-perfil-logo {
  width: 97px;
  height: 97px;
  border-width: 4px;
  border-color: white;
  margin-top: -20px;
}

#banner-mobile-perfil-identificacao {
  font-size: 1.2em;
  line-height: 1;
  margin-left: 10px;
  margin-bottom: 3px;
}

#banner-mobile-perfil-identificacao strong {
  font-weight: 550;
}

#banner-mobile-perfil-identificacao small {
  font-size: 1.2rem;
}

</style>